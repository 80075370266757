<script>
import Api from '@/services/Api';
import IconSmiley from '@/components/IconSmiley.vue';

export default {

  data() {
    return {
      modalIsOpen: false,
      form: {
        name: '',
        company: '',
        phone: '',
        email: '',
        positions: '',
      },
      phone: '',
    };
  },

  components: {
    IconSmiley,
  },

  methods: {

    openForm() {
      this.modalIsOpen = true;
    },

    savePhoneNumber() {
      Api.savePhoneNumber(this.phone)
        .then(() => {
          this.phone = '';
          alert('We just texted you!');
        })
        .catch(() => alert('An error occured, please try again!'));
    },
  },
};
</script>

<template>
  <section class="banner">
    <h2 class="title is-2">
      Heroes Jobs
    </h2>
    <h3 class="subtitle">
      Stand out, own your interview, land the job.
    </h3>
    <p class="banner_value">
      <img src="~@/assets/img/emojis/calendar.png" alt="Personality">
      <br>
      Book your own interviews.
    </p>
    <p class="banner_value">
      <img src="~@/assets/img/emojis/chat_bubble.png" alt="Diversity">
      <br>
      Chat with hiring managers.
    </p>
    <p class="banner_value">
      <img src="~@/assets/img/emojis/tipping_hand.png" alt="Save time">
      <br>
      Keep track of all your job applications in one place.
    </p>

    <div class="banner_conclusion">
      <p>We’ll send you tips and reminders so you’re prepared to ace your next interview.</p>
      <p>No need for a resume or cover letter!</p>
      <p class="banner_conclusion_download">Download the app and discover other great features.</p>
    </div>
    <transition name="fade">
      <div class="banner_modal modal" v-show="modalIsOpen">
        <div class="modal_overlay" @click="modalIsOpen = false"></div>
        <div class="modal_content">
          <button class="modal_close" type="button" @click="modalIsOpen = false">
            <svg width="24" height="24" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
              <path fill="none" d="M8.55,7.968l7.301-7.301c0.153-0.152,0.153-0.399,0-0.551c-0.152-0.152-0.397-0.152-0.55,0L8,7.417
                L0.699,0.116c-0.152-0.152-0.399-0.152-0.551,0s-0.152,0.399,0,0.551l7.301,7.301L0.147,15.27c-0.152,0.151-0.152,0.398,0,0.55
                c0.152,0.153,0.399,0.153,0.551,0L8,8.519l7.301,7.301c0.152,0.153,0.397,0.153,0.55,0c0.153-0.151,0.153-0.398,0-0.55L8.55,7.968z
                "></path>
            </svg>
          </button>
          <h3 class="signUp-title">Welcome!</h3>
          <p class="signUp-intro">On the Heroes Jobs app, you will find companies that are actively hiring right now. Applying takes a minute and the app uses video to show your personality and avoid too many face to face interactions in this time of crisis.</p>
          <p class="signUp-intro">Our team is here to support you and ensure the best experience!</p>
          <img src="https://media.giphy.com/media/FQyQEYd0KlYQ/giphy.gif" width="400" />
          <form class="form" @submit.prevent="savePhoneNumber">
            <input type="tel" placeholder="Phone Number" v-model.trim="phone" required="required">
            <button class="has-smiley" type="submit">
              Get app <icon-smiley />
            </button>
          </form>
        </div>
      </div>
    </transition>
  </section>
</template>

<style lang="scss" scoped>
.banner {
  padding: 52px 0;
  text-align: center;

  &_conclusion {
    margin-top: 100px;
    font-size: 22px;

    &_download {
      margin-top: 30px;
      font-weight: 700;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 10px;
        background: url('~@/assets/img/emojis/raising-hands.png');
        background-size: contain;
      }
    }
  }

  .title {
    margin-top: 50px;
    margin-bottom: 0;
  }

  &_value {
    margin-top: 40px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.4;

    img {
      width: 36px;
    }
  }

  @include touch {
    padding: 28px 0;

    &_value {
      font-size: 24px;
    }
  }

  &_modal {

    .signUp-title {
      text-align: center;
      text-transform: uppercase;
      font-weight: 750;
      font-size: 52px;
      color: #626cf0;
    }

    .signUp-intro {
      margin: 20px 0 20px 0;
      text-align: center;
      font-size: 16px;
    }

    form {
      overflow: hidden;
      position: relative;
      height: 48px;
      max-width: 390px;
      margin: 23px auto 0;
      border: 2px solid $main-color;
      border-radius: 60px;

      input {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0 128px 0 20px;
        font-size: 18px;
        font-weight: 300;
        border: 0;
        background: transparent;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: -28px;
        right: 0px;
        width: 125px;
        height: 100%;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        color: $white-color;
        border: 0;
        border-radius: 60px;
        background: $main-color;
        cursor: pointer;
        outline: none;
      }

    }
  }
}
</style>
