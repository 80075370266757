<script>
export default {
  name: 'bannerTheApp',

  data() {
    return {
      videosTexts: [{
        text: '1. Get tips from our community',
        videomp4: '/videos/employee/new_1.PNG',
      },
      {
        text: '2. Build your professional profile',
        videomp4: '/videos/employee/new_2.png',
      }, {
        text: '3. Apply for jobs near you!',
        videomp4: '/videos/employee/new_3.PNG',
      }],
    };
  },
};
</script>

<template>
  <section class="banner">
    <h2 class="title is-2 is-2">
      The app
    </h2>

    <ol class="banner_videos">
      <li v-for="one in videosTexts" :key="one.text" class="banner_videos_item">
        <img :src="one.videomp4">
        <h2 class="banner_videos_title">{{ one.text }}</h2>
      </li>
    </ol>

  </section>
</template>

<style lang="scss" scoped>
.banner {
  padding: 52px 0;
  text-align: center;

  &_videos {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;

    &_item {
      width: 320px;
    }

    img {
      overflow: hidden;
      display: block;
      width: 100%;
      max-width: 256px;
      margin: auto;
      border-radius: 30px;
      box-shadow: 0 2px 50px rgba($accent-color, 0.5);
      outline: none;
    }

    &_title {
      margin-top: 40px;
      font-size: 22px;
      font-weight: 500;
    }
  }

  @include touch {
    padding: 28px 0;

    &_videos {
      flex-wrap: wrap;

      &_item {
        flex: none;
        width: 100%;
        margin-bottom: 64px;
      }
    }
  }
}
</style>
