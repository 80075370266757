<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

import Api from '@/services/Api';
import BannerTheApp from '@/components/BannerTheApp.vue';
import BannerThePledge from '@/components/BannerThePledge.vue';

export default {
  name: 'home',

  components: {
    BannerTheApp,
    BannerThePledge,
  },

  data() {
    return {
      phone: '',
      faqList: false,
    };
  },

  methods: {
    savePhoneNumber() {
      Api.savePhoneNumber(this.phone)
        .then(() => {
          this.phone = '';
          alert('We just texted you!');
        })
        .catch(() => alert('An error occured, please try again!'));
    },
    onOver() {
      this.faqList = true;
    },
    onLeave() {
      this.faqList = false;
    },
  },

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },

};

</script>

<template>

  <div class="home wrap">

    <header class="home_header">
      <the-nav>
        <div class="nav-side-links">
          <div id="faq-dropdown" class="div-link" @mouseover="onOver" @mouseleave="onLeave">
            <a class="the-nav_link" href="#">FAQ</a>
            <transition name="fade">
              <ul v-if="faqList" class="dropdown-menu">
                <li class="dropdown-menu_item"><a href="/faqemployers">I'm an employer.</a></li>
                <li class="dropdown-menu_item"><a href="/faqjobseekers">I'm a jobseeker.</a></li>
              </ul>
            </transition>
          </div>
          <a href="https://blog.heroes.jobs/" class="the-nav_link" target="_blank">Blog</a>
          <router-link active-class="is-active" :to="{ name: 'home' }">
            Hire now
          </router-link>
        </div>
      </the-nav>

      <h1 class="title">Start your professional journey</h1>
      <h3 class="subtitle">Build your online professional footprint, get career tips, and find jobs on Heroes Jobs.</h3>

      <div class="home_store-btns">
        <a href="https://c-app.heroes.jobs/ios">
          <img src="@/assets/img/appstore.png" alt="Available on the App Store">
        </a>
        <a href="https://c-app.heroes.jobs/android">
          <img src="@/assets/img/playstore.png" alt="Get it on Google Play">
        </a>
      </div>

      <p class="home_scroll-text">
        Check it out, we made this for you.
      </p>

      <div class="bg" ref="bg"></div>
      <div class="bgmobile" ref="bgmobile"></div>
    </header>

    <banner-the-app />
    <banner-the-pledge />
  </div>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1215px) {
  .dropdown-menu_item a {
    font-size: 13px;
    background-color: white;
  }

  .subtitle {
      font-size: 22px;
    }

  .title {
      margin-top: 108px;
      font-size: 48px;
    }
}

@media screen and (min-width: 1216px) {

  .title {
      margin-top: 108px;
      font-size: 56px;
    }

    .subtitle {
      font-size: 24px;
    }

  .dropdown-menu_item {
    padding-top: 5px;
    a {
      font-size: 16px;
    }
  }
}

.div-link {
  display: inline-block;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}

.dropdown-menu {
  position: absolute;
  &_item {
    text-align: left;
    display: block;
    a {
      text-decoration: none;
      text-align: left;
    }
  }
}

.home {
  &_header {
    min-height: 100vh;
    text-align: center;

    &_form {
      overflow: hidden;
      position: relative;
      height: 48px;
      max-width: 390px;
      margin: 28px auto 0;
      border: 2px solid $main-color;
      border-radius: 60px;

      input {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0 128px 0 20px;
        font-size: 18px;
        font-weight: 300;
        border: 0;
        background: transparent;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        right: 0px;
        width: 125px;
        height: 100%;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        color: $white-color;
        border: 0;
        border-radius: 60px;
        background: $main-color;
        cursor: pointer;
        outline: none;
      }
    }
  }

  &_store-btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    a {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 158px;
      height: 52px;
      margin: 0 18px;
      text-decoration: none;
      border-radius: 40px;
      background: $white-color;
      box-shadow: 0 3px 10px rgba($main-color, 0.2);
      transition: $bounce-transition;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &_scroll-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 170px;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 13px;
      height: 20px;
      margin: 0 10px;
      background: url('~@/assets/img/emojis/point-down.png');
      background-size: contain;
    }
  }
}
</style>
